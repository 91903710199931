.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: relative;
}

.containerCenter {
  width: 100%;
}

.containerBackground {
  background: url('/images/dark_gradient.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.main {
  width: 100%;
  height: 100%;
  flex: 1;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.onboardingContainer {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: clamp(1200px, 80vw, 1900px);
  max-width: 100vw;
  padding: 30px;
}

.onboardingContainerPaddingBot {
  padding-bottom: 150px;
}

.navbar {
  padding: 10px 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.signupContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.leftContainer,
.rightContainer {
  height: 100vh;
  display: flex;
  position: relative;
}

.leftContainer {
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 100px 100px 0 rgba(182, 188, 208, 15%);
  width: 50%;
  justify-content: center;
  overflow: hidden auto;
}

.rightContainer {
  width: 50%;
  align-items: center;
  justify-content: flex-start;
}

.loginContainer {
  display: flex;
  background-color: var(--background);
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 15px;
  border: 1px solid var(--border-modal);
  box-shadow: var(--shadow-large);
  padding: 48px 56px;
  box-sizing: border-box;
}

.disablePointer {
  cursor: default;
}

.formContainer {
  width: 400px;
  max-width: 100%;
}

/* 400px + 56*2 = 512, this is the max width of the .loginContainer */
@media screen and (max-width: 512px) {
  .formContainer {
    min-width: 200px;
    max-width: 100%;
  }
}

.resend {
  color: #7979ff;
  cursor: pointer;
}

.dropdown {
  width: 100%;
  min-width: inherit;
}

.dropdown-trigger {
  width: 100%;
}

.dropdown-menu {
  width: inherit;
  min-width: inherit;
}

.dropdown-content {
  width: inherit;
  min-width: inherit;
}

.button {
  display: flex;
  justify-content: space-between;
}

.pin_row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.pin_input {
  width: 56px;
  height: 56px;
  min-width: 56px;
  padding: 13px 0;
  text-align: center;
  border-radius: 8px;
  background-color: var(--deprecated-neutral-75);
  border: 1px solid var(--deprecated-neutral-300);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  box-sizing: border-box;
  font-size: 36px;
  font-weight: 500;
}

.heading {
  font-size: 30px;
  font-weight: 500 !important;
  letter-spacing: -1px !important;
  color: var(--text) !important;
  line-height: 48px;
}

.bigTitle {
  margin-top: 50px;
  margin-bottom: 50px;
  font-size: 25px;
  font-weight: 800;
  color: var(--text);
}

.title {
  margin-bottom: 10px;
  font-size: 20px;
  color: var(--text);
}

.bigSubtitle {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  color: var(--text-light);
}

.subtitle {
  margin-top: 1rem;
  font-size: 20px;
  font-weight: 400;
  color: var(--text-light);
}

.error {
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  padding: 10px 20px;
  border-radius: 10px;
  color: var(--danger);
}

.darkBox {
  background: rgba(0, 0, 0, 0.2);
  padding: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-bottom: 30px;
  width: 500px;
}

.column {
  flex-direction: column;
}

.themeContainer {
  margin: 15px;
  cursor: pointer;
}

.themeContainer > div {
  border-radius: 8px;
}

.themeContainerActive > div {
  border: 2px solid var(--accent);
}

.text {
  border: none !important;
  font-size: 15px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.4);
  text-align: center;
}

.textActive {
  color: #d7d8db;
}

.input {
  width: 300px;
  padding: 10px 15px !important;
  background-color: var(--background-input-dark) !important;
  outline: none;
  border: 1px solid var(--border-input-dark) !important;
  color: var(--text-white) !important;
  border-radius: 4px;
  font-size: 13px !important;
  height: max-content;
  height: 40px;
}

.buttonDark {
  background-color: #1f2124;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 7px 32px 0px;
  border: 1px solid rgb(48, 50, 54);
  color: #7f818d;
}

.smallText {
  font-size: 14px;
}

.buttonContainerBgShadow {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.buttonContainerBg {
  position: fixed;
  background-color: var(--background);
  bottom: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  padding: 30px 30px 50px;
  width: clamp(1200px, 80vw, 1900px);
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.link {
  color: var(--text-light);
  text-decoration: underline;
  cursor: pointer;
}

.dropdownContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loadingContainer .loader {
  height: 40px;
  width: 40px;
  border-bottom-color: var(--accent);
  border-left-color: var(--background-highlight);
}

.bgContainer {
  max-width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
  position: relative;
  background: url('/images/authbg.svg');
  background-size: cover;
  background-repeat: no-repeat;
}

@media only screen and (max-width: 1200px) {
  .leftContainer,
  .rightContainer {
    width: 100%;
    min-height: 100vh;
    height: auto;
  }

  .leftContainer {
    overflow: unset;
  }

  .rightContainer {
    display: none;
    align-items: unset;
    justify-content: center;
  }

  .signupContainer {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;
  }
}

@media only screen and (max-width: 880px) {
  .leftContainer,
  .rightContainer {
    min-height: 100vh;
    width: 100%;
  }

  .rightContainer {
    display: none;
  }

  .signupContainer {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .loginContainer {
    margin: 0px;
    max-width: 100vw;
  }
}

@media only screen and (max-width: 480px) {
  .loginContainer {
    padding: 32px 25px;
  }

  .oauthLoginContainer {
    padding: 32px 25px;
    width: 100%;
    height: 100%;
    box-shadow: none;
    border: none;
    margin: 0px;
    max-width: 100vw;
  }

  .oauthContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    max-height: 100vh;
  }

  .bgContainer {
    background: none;
  }
}

@media only screen and (max-width: 768px) {
  .pin_input {
    width: 48px;
    height: 56px;
    min-width: 48px;
    padding: 8px 0;
  }
}

@media only screen and (max-width: 360px) {
  .pin_input {
    width: 36px;
    height: 40px;
    min-width: 32px;
    padding: 8px 0;
    font-size: 24px;
  }
}
